/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Fragment, lazy, Suspense } from 'react';
import AuthLayout from 'layouts/Auth';
import ApplicationLayout from 'layouts/Application';
import { RouteConfig } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from 'components';
import AuthGuard from './AuthGuard';
import GuestGuard from './GuestGuard';

const routesConfig: RouteConfig[] = [
    {
        path: '/',
        exact: true,
        component: lazy(() => import('routes/Router')),
    },
    {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/errors/error.page')),
    },
    {
        path: '/auth',
        layout: AuthLayout,
        guard: GuestGuard,
        routes: [
            {
                path: '/auth/login/:uuid/confirmation',
                exact: true,
                component: lazy(() => import('views/auth/login/LoginConfirm.page')),
            },
            {
                path: '/auth/login/admin',
                exact: true,
                component: lazy(() => import('views/auth/login/LoginAdmin.page'))
            },
            {
                path: '/auth/login/:uuid',
                exact: true,
                component: lazy(() => import('views/auth/login/Login.page'))
            },
            {
                path: '/auth/reset-password/:uuid',
                exact: true,
                component: lazy(() => import('views/auth/reset_password/resetPassword.page')),
            },
            {
                path: '/auth',
                exact: true,
                component: lazy(() => import('views/auth/welcome/Welcome.page')),
            },
            {
                path: '*',
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/app',
        layout: ApplicationLayout,
        guard: AuthGuard,
        routes: [
            {
                path: '/app/error-404',
                exact: true,
                component: lazy(() => import('views/errors/error.page')),
            },
            {
                path: '/app/users',
                exact: true,
                component: lazy(() => import('views/users/Users.page'))
            },
            {
                path: '/app/group',
                exact: true,
                component: lazy(() => import('views/group/Group.page'))
            },
            {
                path: '/app/home',
                exact: true,
                component: lazy(() => import('views/home/Home.page'))
            },
            // new homepage
            {
                path: '/app/home2',
                exact: true,
                component: lazy(() => import('views/home/Home2.page'))
            },
            {
              path:'/app/invitation',
              exact: true,
                component:lazy(()=>import('views/invitation/Invitation.page'))
            },
            {
                path: '/app/profile',
                exact: true,
                component: lazy(() => import('views/profile/profile.page'))
            },
            {
                path: '/app/users/:username/:statut',
                exact: true,
                component: lazy(() => import('views/clients/clientIsMandant.page'))
            },
            {
                path: '/app/home/:uuid',
                exact: true,
                component: lazy(() => import('views/home/DetailPage.page'))
            },
            {
                path: '/app/article/:parentuuid/:uuid',
                exact: true,
                component: lazy(() => import('views/news/components/articleDetails.page'))
            },
            {
                path: '/app/news/:parentuuid/:uuid',
                exact: true,
                component: lazy(() => import('views/news/components/newPageDetail.page'))
            },
            // {
            //     path: '/app/medias',
            //     exact: true,
            //     component: lazy(() => import('views/media/index'))
            // },
            {
                path: '/app/news',
                exact: true,
                component: lazy(() => import('views/news/News.page'))
            },
            {
                path: '/app/client',
                exact: true,
                component: lazy(() => import('views/clients/Client.page'))
            },

            {
                path: '/app/safe-zone',
                exact: true,
                component: lazy(() => import('views/SafeZone/SafeZone.page'))
            },
            {
                path: '/app/safe-zone/:uuid',
                exact: true,
                component: lazy(() => import('views/SafeZone/SafeZoneFile'))
            },

            {
                path: '/app/logs',
                exact: true,
                component: lazy(() => import('views/logs/Logs.page'))
            },

            {
                path: '/app/documentsProject/:uuid',
                exact: true,
                component: lazy(() => import('views/documents/DocumentProjet.page'))
            },
            {
                path: '/app/documents',
                exact: true,
                component: lazy(() => import('views/documents/Document.page'))
            },

            {
                path: '/app/authorization',
                exact: true,
                component: lazy(() => import('views/autorisations/Authorization.page'))
            },

            {
                path: '/app/trashcan',
                exact: true,
                component: lazy(() => import('views/trashcan/Trashcan.page'))
            },
            {
                path: '/app/home/client/projects/:uuid',
                exact: true,
                component: lazy(() => import('views/home/Project.page'))
            },
            {
                path: '/app/home/client/projects/task/hidden/:uuid',
                exact: true,
                component: lazy(() => import('views/home/HidenTaskPage.page'))
            },
            {
                path: '/app/home/client/projects/deadline/filter/:uuid',
                exact: true,
                component: lazy(() => import('views/home/DeadlineFilter.page'))
            },
            {
                path: '/app/home/client/projects/deadline/filter/:uuid/:user_uuid',
                exact: true,
                component: lazy(() => import('views/home/DeadlineFilter.page'))
            },

            {
                path: '*',
                component: () => <Redirect to="/app/error-404" />
            }
        ]
    }
];

const renderRoutes = (routes: RouteConfig[]) =>
    routes ? (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component: any = route.component;
                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
