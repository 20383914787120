export default {
  translation: {
    'shared.french': 'English',
    'shared.english': 'Deutsch',
    'login_page_welcome': "Herzlich willkommen!",
    'login_page_subtitle': "Anmelden, um fortzufahren.",
    "login_form_email": "E-Mail eingeben",
    'login_form_password': 'Passwort eingeben',
    'login_page_action': 'Anmelden',
    'login_form_require': 'Pflichtfeld',
    'login_page_title': 'Anmeldung',
    'welcome_page_title': 'Herzlich Willkommen im PKF Mandantenportal',
    'welcome_page_subtitle': 'Bitte wählen Sie rechts, Ihre Kanzlei',
    'login_page_forgot_password': 'Haben Sie Ihr Passwort vergessen?',
    "field_require": "Pflichtfeld",
    "confirm_code_title": "Kontobestätigung",
    "confirm_code_subtitle": "Wir haben Ihnen einen Code in Ihr Mailbox geschickt. Prüfen Sie Ihre Mailbox.",
    "confirm_code_caption": "Bitte geben Sie den Code ein, den Sie per E-Mail erhalten haben",
    "confirm_coded_label": "Verifizierungscode eingeben",
    "confirm_code_action": "Bestätigen",
    "confirm_code_retry": "E-Mail erneut senden?",
    "welcome_page_continue": "Zur Anmeldung",
    "reset_page_title": "Passwort zurücksetzen",
    "reset_page_description": "Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Code zum Zurücksetzen Ihres Passworts.",
    "reset_page_action": "Code abschicken",
    "reset_page_remember_title": "Ich erinnere mich an meine ID",
    "reset_page_description_1": "Wir haben Ihnen einen Code zum Zurücksetzen des Passworts geschickt",
    "reset_page_action_1": "Code bestätigen",
    "reset_password_another": "Versuchen Sie es mit einer anderen Adresse",
    "reset_password_resend": "Code erneut senden",
    "reset_page_title_2": "Neues Passwort erstellen",
    "enter_password_receive_byt_mail": "Please enter the code you received by email",
    "project_title_button_1": "Projekte",
    "project_title_button_2": "Verwalten",
    "groups_title_users": "Benutzer",
    "create_new_group": "Neue Gruppe",
    "create_new_group_send": "Neue Gruppe erstellen",
    "create_new_group_placeholder_name": 'Gruppe name ',
    "delete_groupe_message_warning": "Sind Sie sicher, dass Sie diese Gruppe löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden und Sie können keine Daten wiederherstellen.",
    "delete_site_message_warning": "Sind Sie sicher, dass Sie diese Kanzlei löschen? Diese Aktion kann nicht rückgängig gemacht werden und Sie können keine Daten wiederherstellen.",
    "delete_cancel": "Abbrechen",
    "delete_ok": "Ja",
    "delete_title_confirm": "Löschen",
    "add_new_site": "Neuen Benutzer hinzufügen",
    "create_new_site": "Kanzlei hinzufügen",
    "new_site": "Neue Kanzlei",
    "location_site": "Standort",
    "site_name": "Name der Kanzlei",
    "update_new_site": "Update Neue Kanzlei",
    "create_new_user_action": "Benutzer hinzufügen",
    "create_att": "Erstellt am",
    "edit_att": "Zuletzt geändert:",
    "create_by": "erstellt von:",
    "by": "Von",
    "upload_blind": "Upload Bild",
    "create_new_group_placeholder_ceo_name": 'Name des CEO',
    "previous": "Zurück",
    "next": "Weiter",
    "update_new_group": "Gruppe aktualisieren",
    "show_all_site": "Alle Standorte anzeigen...",
    "shared.internet_connexion_error": "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
    "login_form_password_new": "Neues Passwort",
    "login_form_password_new_confirmation": "Passwort bestätigen",
    "edit_site": "Kanzlei bearbeiten",
    "delete_site": "Kanzlei löschen",
    "add_new_user": "PKF-Benutzer hinzufügen",
    "translate_key_existing_user":"Benutzer auswählen",
    "admin_space": "Verwaltungsbereich",
    "group_name": "Gruppe",
    "group_remember": "Erinnern Sie sich daran, eine Seite zu Ihrer Gruppe hinzuzufügen",
    "reset_password_dont_receive": "Sie haben keine E-Mail erhalten? Prüfen Sie Ihre SPAM-Boxen oder",
    "top_bar_profile_tooltip": "Profil",
    'top_bar_notif_tooltip': "Benachrichtigung",
    "search_new_user": "Benutzer suchen",
    "create_new_user": "Neuer Benutzer",
    "update_new_user_action": "Benutzer aktualisieren",
    "create_new_user_firstname": "Vorname",
    "create_new_user_lastname": "Nachname",
    "create_new_user_email": "E-Mail",
    "create_new_user_role": "Wählen Sie eine Rolle für diesen Benutzer",
    "create_new_user_password": "Neues Passwort erstellen",
    "create_new_user_status": "Definieren Sie einen Status für diesen Benutzer",
    "create_new_user_status_UNVALIDATED": "Ungültig",
    "create_new_user_status_ACTIVED": "Aktiv",
    "create_new_user_status_UNACTIVED": "Inaktiv",
    "create_new_user_status_DELETED": "Löschen",
    "field_require_email": "Bitte geben Sie eine gültige E-Mail ein",
    "update_new_user": "Benutzer aktualisieren",
    "delete_new_user": "Benutzer löschen",
    "page_users_title": "PKF Mitarbeiter",
    "page_clients_title": "PKF Mandanten Übersicht",
    "page_users_filter": "Sortierung nach:",
    "page_users_name": "Name",
    "page_users_project":"Projekte",
    "page_users_client":"Mandant",
    "page_users_status":"Status",
    "page_users_email": "E-mail",
    "page_users_role": "Rolle",
    "page_users_add": "Hinzufüngt am",
    "page_users_edit": "Bearbeiten",
    "page_users_sites": "Kanzlei",
    "page_users_activity": "Letzte Aktivität",
    "page_users_add_by": "erstellt von :  ",
    "page_users_add_by_document": "Erstellt von  ",
    "page_users_actions": "Aktionen ",
    "page_users_date": "Erstellt am",
    "group_add_success": "Erfolg beim Erstellen einer Gruppe",
    "site_add_success": "Kanzlei erstellt",
    "site_update_success": "Kanzlei aktualisiert",
    "clients_for_site": "Mandanten in dieser Kanzlei",
    "projet_for_site": "Projekte für diese Kanzlei",
    "location_users": "Wählen Sie einen Standort aus, an dem sich der Benutzer befinden soll",
    "new_client": "Neuer Mandant",
    "search_client": "Mandant suchen",
    "add_new_client_title": "Einen neuen Mandant hinzufügen",
    "title_add_datev_mandant":"Geben Sie den Kundennamen ein, wir werden ihn in der Datev-Datenbank suchen, um Ihnen beim Import zu helfen.",
    "translate_key_add_imported_client_title":"Mandant von datev importieren",
    "button_add_client": "Mandant hinzufügen",
    "update_client": "Mandant aktualisieren",
    "official_name": "Offizieller Name",
    "interner_name": "Interner Name",
    "client_title_page": "Mandant",
    "edit_client": "Diesen Mandanten bearbeiten",
    "delete_client": "Mandant löschen",
    "client_create_success": "Mandant erfolgreich erstellt",
    "client_update_success": "Mandant erfolgreich aktualisiert",
    "client_show_details": "Details anzeigen",
    "delete_client_message_warning": "Sind Sie sicher, dass Sie diesen Mandanten löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden, und Sie können keine Daten wiederherstellen",
    "client_detail_detail": "Details anzeigen",
    "page_users_modify": "Zuletzt geändert",
    "users_filter_by_date_added": "Datum hinzugefügt",
    "users_filter_by_last_activity": "Letzte Aktivität",
    "users_filter_by_firstname": "Vorname",
    "users_filter_by_lastname": "Nachname",
    "users_filter_by_email": "E-Mail",
    "users_filter_by_no_role": "Keine Rolle haben",
    "users_filter_by_unactive": "Inaktiv",
    "client_detail_added": "Hinzugefügt:",
    "client_detail_added_by": "Hinzugefügt von:",
    "client_detail_last_edit": "Letzte Bearbeitung:",
    "client_detail_last_edit_by": "Bearbeitet von:",
    "manage_user_action": "Verwalten",
    "manage_user_new_project": "Neues Projekt hinzufügen",
    "client_detail_users": "Diese Benutzer können {{enterprise}} sehen",
    "client_detail_users_locked": "Diese Benutzer können {{enterprise}} nicth sehen",
    "users_updated_success": "Benutzerliste erfolgreich aktualisiert",
    "client_detail_users_dialog_input": "Neuen Benutzer hinzufügen",
    "auto_complete_empty_user": "Kein Benutzer gefunden",
    "customers_filter_by_date_added": "Datum hinzugefügt",
    "clients_filter_by_last_activity": "Letzte Änderung",
    "clients_filter_by_date_added": "Datum hinzugefügt",
    "clients_filter_by_internalname": "Interner Name",
    "clients_filter_by_official_name": "Offizieller Name",
    "customers_filter_by_email": "E-Mail",
    "create_new_user_site": "Wählen Sie eine Seite für diesen Benutzer aus",
    "create_new_client_site": "Wählen Sie eine Seite für diesen mandant aus",
    "add_user_description": "Dieser neue Benutzer wird automatisch zur Website {{website}} hinzugefügt",
    "user_created_success": "Benutzerkonto erfolgreich erstellt",
    "user_update_success": "Benutzerkonto erfolgreich aktualisiert",
    "user_delete_success": "Benutzerkonto erfolgreich gelöscht",
    "user_delete_confirm": "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
    "delete_client_user_message_warning": "Sind Sie sicher, dass Sie diesen Benutzer entfernen möchten?",
    "home_search_project_title": "Mandant suchen",
    "home_search_task": "Suchen...",
    "detail_home_title": "Meine Projekte",
    "detail_home_list": "Aufgabenliste erstellen",
    "detail_home_template": "Vorlagen",
    "detail_home_status": "Status",
    "detail_home_comments": "Kommentare",
    "detail_home_activities": "Aktivitäten",
    "detail_home_status_open": "Offen",
    "detail_home_status_accepted": "Erledigt",
    "detail_home_status_week": "Diese Woche fällig",
    "detail_home_status_late": "Verspätung",
    "detail_home_status_rejected": "Abgelehnt",
    "detail_home_user": "Benutzer",
    "detail_home_task": "Versteckte Aufgabe",
    "activity_tabs_title": "Aktivitäten",
    "delete_document_success": "{{document}} erfolgreich gelöscht",
    "login_form_role": "Rolle",
    "title_new_site": "Seite",
    "clients_filter_by_email": "E-Mail",
    "create_task_date": "Erstellt am",
    "join_on_title": "Mach mit",
    "project_on": "Projekte auf",
    "project_done": "Projekt abgeschlossen",
    "tasks_on": "Aufgaben an",
    "tasks_done": "Aufgaben erledigt",
    "profil_title_user": "Profilnutzer",
    "profile_change_title": "Profilbild ändern",
    "show_picture": "Bild ansehen",
    "change_picture_profile": "Ändere das Bild",
    "delete_picture_user": "Bild wird gelöscht",
    "file_updated_by": "Aktualisiert von",
    "document_list_create_success": "Dokument erfolgreich hinzugefügt",
    "document_list_delete_success": "Dokument erfolgreich gelöscht",
    "confirm_loadtemplate": "Vorlage laden",
    "load_template_action": "Ja ! Laden",
    "load_template_success": "Template erfolgreich geladen",
    "confirm_load_template_or_group": "Sind Sie sicher, dass Sie {{template}} ausgewählte Vorlage laden möchten?",
    "document_action_hide": "Ausblenden",
    "document_action_delete": "Löschen",
    "document_action_awaiting": "Geöffnetes Dokument",
    "document_action_accepted": "PKF Erledigt",
    "document_action_rejected": "PKF Abgelehnt",
    "document_confirm_delete": "Möchten Sie dieses Dokument wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden und Sie können keine Daten wiederherstellen ",
    "document_confirm_hide": "Sind Sie sicher, dass Sie dieses Dokument ausblenden möchten?",
    "document_confirm_change_status": "Sind Sie sicher, dass Sie den Status dieses Dokuments ändern möchten?",
    "document_confirm_change": "Bestätigung",
    "save_task_update": "Speichern",
    "client_detail_users_dialog_title": "Hinzugefügte Mandant Usern zu Project einladen",
    "client_detail_users_dialog_action": "Bestätigen",
    "home_task_progress": "Aufgabe in Bearbeitung",
    "home_task_outdate": "Überfällige",
    "home_task_list_title": "Laufende Aufgaben",
    "home_task_project_detail": "Weitere Details",
    "home_mobile_dialog_title": "Aufgabenkalender",
    "home_task_project_message": "{{length}} Nachrichten",
    "home_task_project_task": "{{length}} Ausstehende Aufgaben",
    "home_task_done": "Aufgabe erledigt",
    "home_task_add": "Aufgabe",
    "button_detail_open": "Offen",
    "select_status": "Status auswählen",
    "select_option_deadline": "Noch keine Frist -",
    "upload_file_placeholder": "Dokument hochladen",
    "browser_file": "Datei durchsuchen",
    "type_flie_upload": "Alle Arten von Dokumenten (Word, PDF, Excel, etc...).",
    "max_size_upload": "Maximale Größe pro Datei 250MB",
    "no_upload_yet": "Noch kein Dokument hochgeladen",
    "comment_tabs_title": "Kommentare",
    "activitie_tabs_title": "Aktivitäten",
    "project_dialog_title": "Ein neues Projekt hinzufügen",
    "project_dialog_update_title": "Projekt aktualisieren",
    "project_dialog_add_action": "Projekt hinzufügen",
    "project_dialog_desc": "Beschreibung",
    "project_dialog_deadline": "Abgabetermin",
    "email_resend_successfully": "Code per E-Mail erfolgreich erneut gesendet",
    "project_created_success": "Projekt erfolgreich erstellt",
    "project_update_success": "Projekt erfolgreich aktualisiert",
    "not_project_available": "Für diesen Kunden ist kein Projekt verfügbar",
    "task_role_owner": "(Projekt Besitzer)",
    "task_role_collaborator": "(Teilnehmer)",
    "task_role_mandant": "Mandant",
    "login_super_admin": "Admin Anmeldung",
    "email_form_invalid": "Bitte gültige E-Mail-Adresse eingeben",
    "login_super_admin_description": "Anmeldung als Admin",
    "task_users_all": "Alle Teilnehmer anzeigen",
    "task_list_create_success": "Aufgabenliste erfolgreich erstellt",
    "task_list_update_success": "Aufgabenliste erfolgreich aktualisiert",
    "task_list_delete_success": "Aufgabenliste erfolgreich gelöscht",
    "task_create_success": "Aufgabe erfolgreich erstellt",
    "task_update_success": "Task erfolgreich aktualisiert",
    "task_delete_success": "Aufgabe erfolgreich gelöscht",
    "status_update_success": "Status wurde erfolgreich aktualisiert",
    "delete_task_update_success": "Aufgabenliste und/oder Aufgabe wurde erfolgreich gelöscht",
    "grant_task_update_success": "Liste der autorisierten Benutzer wurde erfolgreich aktualisiert",
    "all_template_list": "Alle Vorlagen",
    "my_template_list": "Meine Vorlagen",
    "stardard_template_list": "Standard Vorlagen",
    "edit_template_menu": "Vorlage bearbeiten",
    "delete_template_menu": "Vorlage löschen",
    "load_template_menu": "Vorlage laden",
    "delete_message_template": "Sind Sie sicher, dass Sie diese Vorlage löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden, und Sie können keine Daten wiederherstellen",
    "site_delete_success": "Standort löschen erfolgreich",
    "group_update_success": "Gruppenaktualisierung erfolgreich",
    "create_template_button": "Neue leer Vorlage erstellen",
    "edit_template_button": "Vorlagen bearbeiten",
    "manager_template_button": "Vorlagen verwalten / laden",
    "create_new_template_title": "Neue Vorlage erstellen",
    "update_new_template_title": "Neue Vorlage aktualisieren",
    "delete_template_success": "Vorlage erfolgreich löschen",
    "delete_task_success": "Aufgabe erfolgreich löschen",
    "update_template_success": "Vorlage erfolgreich aktualisiert ",
    "update_task_success": "Aufgabe erfolgreich aktualisiert: ",
    "create_template_success": "Vorlage erfolgreich erstellt",
    "create_task_success": "Aufgabe erfolgreich erstellt",
    "add_task_list": "Aufgabenliste hinzufügen",
    "add_placeholder_task_list": "Neue Aufgabenliste hinzufügen...",
    "home_task_project": "Projekt",
    "home_task_project_document": "{{length}} Dokumente",
    "user_page_title": "Benutzer",
    "update_user_menu": "Diesen Benutzer aktualisieren",
    "delete_user_menu": "Diesen Benutzer löschen",
    "view_user_tasks": "Zugewiesene Aufgaben anzeigen",
    "auto_complete_loading": "Laden...",
    "auto_complete_keyword_empty": "Kein Ergebnis gefunden",
    "auto_complete_empty_health_personal": "Keine Seite gefunden",
    "auto_complete_empty": "Geben Sie ein Schlüsselwort ein",
    "home_page_title": "Startseite",
    "add_task": "Aufgabe hinzufügen",
    "update_template_task_group": "Vorlage Aufgabengruppe aktualisieren",
    "update_template_group": "Vorlage Aufgabe aktualisieren",
    "delete_message_task_group_template": "Sind Sie sicher, dass Sie den Aufgabengruppennamen :\" {{task}} \" ? Diese Aktion kann nicht rückgängig gemacht werden und Sie können keine Daten wiederherstellen ",
    "delete_message_task_template": "Sind Sie sicher, dass Sie den Aufgabennamen löschen wollen : \" {{task}}\"  ? Diese Aktion kann nicht rückgängig gemacht werden und Sie können keine Daten wiederherstellen ",
    "new_user_in_client_site_name": "{{name}} automatisch",
    "edit_user_client": "Diesen Benutzer bearbeiten",
    "delete_user_client": "Diesen Benutzer löschen",
    "users_filter_by_last_date_modified": "version vom",
    "clients_filter_by_date_modified": "Änderungsdatum",
    "see_all_projet": "Alle Projekte anzeigen",
    "see_all_users": "Alle Benutzer anzeigen",
    "udpate_task": "Aufgabe aktualisieren",
    "udpate_tasklist": "Aufgabenliste aktualisieren",
    "delete_task": "Aufgabe löschen",
    "confirm_delete_task_or_group": "Sind Sie sicher, dass Sie ausgewählte Aufgabenlisten und/oder Aufgaben löschen wollen?",
    "status_to_text_OPEN": "Offen",
    "status_to_text_ACCEPTED": "Erledigt",
    "status_to_text_IN_PROGRESS": "In Bearbeitung",
    "status_to_text_REJECTED": "Abgelehnt",
    "status_to_text_SUFFERING": "Auffällig",
    "status_to_text_DUE_THIS_WEEK": "FÄLLIG DIESE WOCHE",
    "status_to_text_DONE": "Done",
    "document_action_sign": "Unterschreiben",
    "document_sign_title": "Dokument unterschreiben",
    "document_request_sign_title": "Zeichen anfordern",
    "document_request_sign_action": "Dokument unterschreiben",
    "document_request_sign_customer": "Kunden finden",
    "document_action_sign_user_name": "Benutzername",
    "start_sign": "Dokument unterschreiben",
    "validate_new_user_sign": "Hinzufügen",
    "save_user_to_sign": "Anfrage senden",
    "create_new_task": "Neue Aufgabenliste erstellen",
    "internal_name": "Interner Name",
    "create_new_task_in": "Neue Aufgabe erstellen in",
    "list_of_delete_group_tasks": "This is the list of groups task you want to delete,Sie müssen die gewünschte Aufgabengruppe auswählen, um die Löschung zu bestätigen",
    "edit_task": "Aufgabe bearbeiten",
    "new_user_in_client": "Dieser neue Benutzer wird hinzugefügt",
    "update_user_in_client": "Dieser Benutzer wird auf den neuesten Stand gebracht",
    "empty_safe_zone_title": "Sie haben noch keinen Safe.",
    "empty_safe_zone_action": "Meinen Safe erstellen",
    "create_safe_zone_description": "Definieren Sie einen Geheimcode, mit dem Sie auf Ihren Safe zugreifen können. Sie werden jedes Mal gefragt, wenn Sie versuchen, den Safe zu offen",
    "create_safe_zone_code": "Geheimcode",
    "safe_zone_structure_title": "Struktur verwalten",
    "create_safe_zone_question": "Legen Sie Ihre Geheimfrage fest",
    "create_safe_zone_response": "Beantworten Sie die Frage",
    "empty_safe_zone_subtitle": "Sie haben noch keinen Safe. Sie können jetzt einen erstellen und Ihre vertraulichen Dokumente darin speichern. Nur Sie können darauf zugreifen",
    "log_page_search_title": "Suche im Protokoll nach Aufgaben- oder Dokumentennamen usw.",
    "log_page_title": "Protokolle",
    "test_form_details_profile": "E-Mail",
    "title_management_user_title": "PKF Mitarbeiter",
    "test_form_details_profile_site": "Kanzlei Partner",
    "test_form_details_profile_date": " Angemeldet seit",
    "set_tab_container": "Legen Sie eine Erinnerung fest",
    "set_reminder_title1": "Es wurden noch keine Einträge vorgenommen",
    "set_reminder_title2": "Möchten Sie eine Erinnerung für diesen Kunden hinzufügen?",
    "set_reminder_button_title": "Erinnerung einrichten",
    "download_document": "Dokument herunterladen",
    "download_sign_document": "Signaturdokument herunterladen",
    "new_client_contact": "Ansprechpartner",
    "new_client_infos": "Kundeninformationen",
    "safe_zone_page_title": "Sichere Zone",
    "safe_zone_structure_settings": "Einstellung",
    "folder_table_name": "Ordner",
    "folder_table_files": "Dateien",
    "folder_table_date_added": "Erstellungsdatum",
    "folder_table_date_modified": "Zuletzt geändert",
    "file_table_name": "Dateiname",
    "file_table_size": "Größe",
    "file_table_date_added": "Erstellungsdatum",
    "file_table_date_modified": "Zuletzt geändert",
    "logged_safe_zone_title": "Meine sichere Zone",
    "logged_safe_zone_subtitle": "Geben Sie Ihre Daten ein, um sich in Ihrer sicheren Zone anzumelden",
    "logged_safe_zone_action": "Anmelden",
    "add_document_key": "Dokument hinzufügen",
    "update_document_action": "Dokumente hochladen",
    "delete_confirmation": "Sind Sie sicher, dass Sie {{file}} löschen wollen?",
    "activity_lock": "Activity Log",
    "project_status_label": "Projektstatus",
    "project_status_archived": "Archiviert",
    "project_status_un_archived": "Nicht archiviert",
    "listing_task": "Auflistung der Aufgaben ",
    "warning_safe_zone_title": "Warnung",
    "warning_safe_zone_validation": "Okay",
    "warning_safe_zone_message": "Die Nutzung der SafeZone ist auf ihren Besitzer beschränkt. Die betrügerische Nutzung der SafeZone einer anderen Person kann zu strafrechtlichen Sanktionen führen.",
    "listing_project": "Auflistung der Projekte des Benutzers",
    "title_button": "Bleibt diese Aufgabe unverändert?",
    "title_change_unchanged": "Aufgabe bleibt unverändert bestehen",
    "title_change_confirm": "Ja! Diese Aufgabe blieb unverändert",
    "task_unchange_description": "Sind Sie sicher, dass die Aufgabe  {{task}}  gegenüber dem letzten Jahr unverändert ist? Diese Änderung wird für alle anderen Benutzer mit Zugriff auf dieses Projekt sichtbar sein",
    "task_not_sure_changed": "Ich bin nicht sicher",
    "task_unchanged_from_last_year": "Unverändert vom letzten Jahr",
    "forget_safe_zone_code": "Meinen Code zurücksetzen",
    "forget_safe_zone_question": 'Meine Frage zurücksetzen',
    "hide_project": "Ausblenden",
    "message_hide_task": "Sind Sie sicher, dass Sie diese Aufgabe ausblenden wollen ",
    "hidden_task_title": "Versteckte Aufgabe",
    "task_mask_success": "Aufgabe erfolgreich verstecken",
    "task_folder_title": "Versteckte Aufgaben",
    "document_menu_title": "Unterlagen",
    "download_title": "Herunterladen",
    "document_selected": "Ausgewählte Dokumente",
    "new_subgroup_title_create": 'Neue Subgruppe',
    "apply_to_safe_zone_title": "Auf die sichere Zone anwenden",
    "reapply_to_safe_zone_title": "Wiedereintritt in die sichere Zone ",
    "text_on_asked_to_safe_zone": "Hier befindet sich Ihr persönlicher (Firmen-)Datensafe! Dieser ermöglicht es Ihnen, Ihre wichtigsten Dokumente wie Testament, Verträge, Passwörter usw. strukturiert abzulegen. Ihre Anfrage wurde von Ihrem PKF!-Ansprechpartner bearbeitet und befindet sich in der Validierungsphase.",
    "text_on_reapply_to_safe_zone": "Hier befindet sich Ihr persönlicher (Firmen-)Datensafe! Dieser ermöglicht es Ihnen, Ihre wichtigsten Dokumente wie Testament, Verträge, Passwörter usw. strukturiert abzulegen. Ihr vorheriger Antrag wurde abgelehnt, um die Aktivierung erneut zu beantragen, wenden Sie sich an Ihren PKF-Ansprechpartner!",
    "text_on_apply_to_safe_zone": "Hier befindet sich Ihr persönlicher (Firmen-)Datensafe! Hier können Sie Ihre wichtigsten Dokumente, wie z.B. Testamente, Verträge, Passwörter etc. strukturiert ablegen. Um ihn zu aktivieren, wenden Sie sich bitte an Ihren PKF-Ansprechpartner!",
    "text_send_button": "Senden",
    "accepted_request_button": "Antrag annehmen",
    "refused_button": "Ablehnen",
    "asked_safe_zone": "Antrag auf Aktivierung ",
    "task_filter": "Aufgabe",
    "task_group_filter": "Aufgabengruppe",
    "file_created_by": "Erstellt von {{name}}\nAm {{date}}",
    "add_button_authorization_title": "Teilnehmer  hinzufüngen",
    "login_with_microsoft_text": "In 1 click with your $microsoft account",
    "azure_approvisionnement": "Informationen zur Azure AD-Beschaffung",
    "azure_approvisionnement_description": "Azure AD benötigt die folgenden Informationen, um sich mit der Mandanten Portal API zu verbinden und die Benutzerdaten zu synchronisieren",
    "block_azure_action_copy": "Copy",
    "block_azure_doc": "Azure AD provisioning configuration procedures via SCIM",
    "pdf_viewer_default_title": "Document",
    "azure_doc_title": "Procedure for configuring Azure AD provisioning via SCIM",
    "azure_doc_description": "Use the Azure Active Directory portal to create and configure an application for automatic provisioning. In our case, we will perform the configuration with Mandanten Portal. Follow the following steps in order",
    "azure_doc_open": "Open",
    "azure_doc_open_2": "the Azure AD portal",
    "azure_doc_open_3": "and select",
    "azure_doc_open_4": "Enterprise Applications",
    "azure_doc_step_2_a": "Rest assured that",
    "azure_doc_step_2_b": "All applications",
    "azure_doc_step_2_c": "are checked in the field",
    "azure_doc_step_2_d": "Manage",
    "azure_doc_step_2_e": "then click on",
    "azure_doc_step_2_f": "New application",
    "azure_doc_step_3_a": "Now click on",
    "azure_doc_step_3_b": "Create your own application",
    "azure_doc_step_3_c": "A sliding block is displayed. Enter the name of the application and check that the option",
    "azure_doc_step_3_d": "Integrate any other application you don't find in the gallery (Non-gallery)",
    "azure_doc_step_3_e": "be checked. Then click on the button",
    "azure_doc_step_3_f": "Create",
    "azure_doc_step_4_a": "Perfect! Your application is created. Click on",
    "azure_doc_step_4_b": "Provisioning",
    "azure_doc_step_5_a": "Click on",
    "azure_doc_step_5_b": "Start",
    "azure_doc_step_6_a": "Go to Mandanten Portal and copy the content of the fields",
    "azure_doc_step_6_b": "URL",
    "azure_doc_step_6_c": "and",
    "authorization_title": "Übersicht über die Berechtigungen",
    "azure_doc_step_6_d": "Token uuid",
    "azure_doc_step_8_a": "Paste the content of the field",
    "azure_doc_step_8_b": "in the field",
    "azure_doc_step_8_c": "Tenant URL",
    "azure_doc_step_8_d": "Do the same with ",
    "azure_doc_step_8_e": "Secret Token",
    "azure_doc_step_8_f": "Test the connections with Mandanten Portal by clicking on",
    "azure_doc_step_8_g": "Test the connection",
    "azure_doc_step_8_h": "If all goes well, click on",
    "azure_doc_step_8_i": "Save",
    "azure_doc_step_9_a": "Then click on the name of your application in the breadcrumb trail.",
    "azure_doc_step_10_a": "Then click on",
    "azure_doc_step_10_b": "Update credentials",
    "azure_doc_step_11_a": "Select a user",
    "azure_doc_step_11_b": "and ",
    "azure_doc_step_11_c": "click on the select button to add them to an assignment",
    "azure_doc_step_13_a": "Select the user",
    "azure_doc_step_13_b": " you want ",
    "azure_doc_step_13_c": "to use depending on the group and its role",
    "azure_doc_step_12": "Congratulations, you have just completed the Azure AD provisioning configuration via SCIM.",
    "modal_change_password_title": "Passwort zurücksetzen",
    "modal_change_password_description": "Bevor Sie fortfahren, ist es notwendig, Ihr Passwort zu ändern",
    "label_password_updated": "Passwort aktualisiert",
    "label_password_is_not_same": "Die beiden Passwörter stimmen nicht zu",
    "btn_edit_password": "Bearbeiten",
    "label_password": "Passwort",
    "label_current_password": "Aktuelles Passwort",
    "title_new_button": "News",
    "choose_title_theme": "Ihr individueller Newsletter – per „klick“ zu aktuelle News!",
    "subtitle_theme": "Wählen Sie hier Ihre Branche oder Ihr Thema.",
    "title_button_theme": "Entdecken Sie meinen Newsfeed",
    "back": "Back",
    "bloc_title_image": "Über diesen Artikel",
    "btn_see_article": "Artikel ansehen",
    "label_related_articles": "Verwandte Artikel",
    "label_trashcan": "Papierkorb",
    "page_trashcan_title": "Gelöschte Einträge",
    "label_clients": "Kunden",
    "label_projects": "Projekte",
    "deleted_user_table_head_name": "Kunde",
    "deleted_user_table_head_project_name": "Name des Projekts",
    "deleted_user_table_head_delete_date": "Löschdatum",
    "deleted_user_table_head_project_count": "Anzahl der Projekte",
    "deleted_user_table_head_task_count": "Anzahl der Aufgaben",
    "label_delete_by": "Gestrichen von : ",
    "action_delete_forever": "Dauerhafte Löschung",
    "action_restore": "Wiederherstellen",
    "restore_dialog_confirm": "Verpflegung",
    "restore_dialog_message": "Sie sind dabei, einen oder mehrere Klienten wiederherzustellen. Ihre Projekte und Einstellungen werden automatisch auf die Konfiguration vor dem Löschen zurückgesetzt. ",
    "restore_project_dialog_message": "Sie sind dabei, ein oder mehrere Projekte wiederherzustellen. Die Aufgaben und Einstellungen werden automatisch auf die Konfiguration vor dem Löschen zurückgesetzt. ",
    "delete_clients_message": "Möchten Sie diese(n) Klient(en) endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden. ",
    "delete_projects_message": "Möchten Sie dieses/diese Projekt/e dauerhaft löschen? Diese Aktion kann nicht rückgängig gemacht werden. ",
    "empty_trash_message": "Kein kürzlich gelöschtes Element",
    "simple_delete_project_dialog_message": "Wollen Sie das Projekt wirklich streichen?",
    "delete_project_success": "Projekt erfolgreich gelöscht",
    "choose_person_to_lock": "Person zum Sperren auswählen",
    "locked_users_menu": "Gesperrte Benutzer",
    "project_title_button_3": "News",
    "home_page_project_filter": "Sortierung nach:",
    "home_filter_by_date_added": "Erstellungsdatum",
    "home_filter_by_deadline": "Fristen",
    "home_filter_by_officialname": "Alphabetische Reihenfolge",
    "home_filter_by_completed_projects_percentage": "Prozentualer Anteil der Fertigstellung",
    "home_filter_by_keine": "Keine",
    "project_page_title": "Projekte",
    "page_close": "Schließen",
    "strong_password_error": "Ihr Passwort muss mindestens 8 Zeichen, einen Großbuchstaben, eine Nummer und ein spezielles Zeichen wie @, +, =,$,#,.,_,¨^",
    "signature_request_send_success": "Signaturanforderung wurde erfolgreich gesendet",
    "default_reminders": "Standarderinnerungen",
    "2_days_before": "2 Tage vorher",
    "3_week_before": "3 Wochen vorher",
    "1_week_before": "1 Woche vorher",
    "4_week_before": "4 Wochen vorher",
    "2_week_before": "2 Wochen vorher",
    "6_week_before": "6 Wochen vorher",
    "label_tasks": "Aufgaben",
    "label_groups": "Gruppen",
    "show_project_task": "Anzeigen",
    "message_view_task": "Möchten Sie diese Aufgabe(n) oder Aufgabengruppe(n) sichtbar machen ?",
    "view_task_title": "Aufgabe ansehen",
    "details_project_page_title": "Einzelheiten zum Projekt",
    "default_doc_or_commment_reminders": "Erinnerungsrate für Kommentare und Dokumente ",
    "document_or_comment_daily": "täglich",
    "document_or_comment_weekly": "wöchentlich",
    "document_or_comment_monthly": "monatlich",
    "document_or_comment_never": "Niemals",
    "create_personal_template_button": "Projekt als Vorlage erstellen",
    "notified_users": "Aufgabe einem Benutzer zuweisen",
    "choose_person_to_be_notified": "zu benachrichtigende Person auswählen",
    "sign_in_with_microsoft": "Mit Microsoft einloggen",
    "add_new_benetzur_visibility": "Benutzer hinzufügen",
    "user_client_exist": "bestehende Benutzer",
    "manage_users_menu": "Gesperrte Mandant",
    "payload_general_templates_created_with_success": "Allgemeine Vorlage mit Erfolg erstellen",
    "move_task_title": "Verschieben des Flecks {{name}} ",
    "actual_version": "aktuelle version",
    "new_features": "Neue Funktionalitäten",
    "delete_group_template": "Diese Aufgabe ist die letzte in ihrer Aufgabengruppe. Ihr Löschen führt auch zum Löschen dieser Aufgabengruppe.",
    "continue_with_risk_text": "trotzdem löschen?",
    "move_task_title_button": "verschiebt die Aufgabe",
    "update_for": "Aktualisierung für",
    "not_move_warning_message": "Leider können Sie eine Aufgabe nicht innerhalb derselben Gruppe verschieben. ",
    "all_projects": "Alle Mandanten",
    "favorites_projects": "Meine Mandanten",
    "task_deadline_filter": "Meine Aufgaben",
    "global_menu": "Menü",
    "authorization_title_btn_1": "Berechtigungen Übersicht",
    "authorization_title_btn_2": "Verwaltung von Berechtigungen",
    "select_client": "Einen Klienten auswählen",
    "select_mandant": "Mandant auswählen",
    "authorization_delete_confirm": "Möchten Sie den Zugriff auf diese Aufgabe oder Aufgabengruppe entziehen ?",
    "add_task_data_label_title": "Neuen Ordner anlegen",
    "move_tasklist_title": "Drag und Drop",
    "disabled_title": "Sperren",
    "add_visibility_title_button": "Bestätigen",
    "make_task_done": "Aufgabe erledigt",
    "global_label_confirm": 'Bestätigen',
    "message_modal_confirm_task_done": "Möchten Sie diese Aufgabe wirklich als erledigt markieren?  ",
    "my_task_filter": "Aufgaben",
    "due_date_filter": "Meine Fristen",
    "my_task_wihtout_deadline": "Aufgaben ohne Frist",
    "title_update_multiple_task_deadline": "Aktualisierung der Deadlines ausgewählter Aufgaben",
    "other_action": "Andere Aktionen",
    "done_by_mandanten": "Zugewiesen Aufgaben Erledigt",
    "confirm_delete_comment": "löschen wie",
    "message_delete_comment": "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten? ",
    "task_for_the_day": "Aufgabe für den heutigen Tag",
    "document_title": "Unterlagen",
    "document_title_project": "Unterlagen {{name}} ",
    "no_data_home_page_message": "Sie haben derzeit keinen Zugang zu Mandanten",
    "not_take_it_change": "Sorry! Diese Erweiterung wird nicht unterstützt",
    "list_of_user_title": "Liste der Benutzer",
    "impersonate": "verkörpern",
    "message_impersonate": 'Möchten Sie sich wirklich als : {{user}}',
    "message_impersonate_connected": 'Sie sind jetzt eingeloggt als  : {{user}}',
    "document_preview": 'Vorschau',
    "double_2FA_title": 'Zwei-Faktor-Authentifizierung',
    "translate_key_receive_mail_title": "Post empfangen",
    "double_2FA_current_status": "Aktueller Status: {{enable}} ",
    "translate_key_receive_mail_current_status": "Current state {{enable}}",
    "double_2FA_enable_switch": "{{activate}}  Sie die Zwei-Faktor-Authentifizierung",
    "translate_key_receive_mail_enable_switch": "Empfang von E-Mails {{activate}}",
    "double_2FA_description": "Verwenden Sie einen One-Time-Password-Authentifikator auf Ihrem mobilen Gerät oder Computer, um die Zwei-Faktor-Authentifizierung (2FA) zu aktivieren. Wir empfehlen Cloud-basierte mobile Authentifizierungs-Apps wie Authy, Google Authetificator, Duo mobile und LastPass. Sie können den Zugang wiederherstellen, wenn Sie Ihr Hardware-Gerät verlieren.",
    "double_2FA_code": "Bitte geben Sie den Code ein, den Sie in Ihrer App erhalten habenBitte geben Sie den Code ein, den Sie in Ihrer App erhalten haben",
    "double_2FA_submit": "Melden Sie sich mit der Zwei-Faktor-App an",
    "translate_key_active": "Aktivieren",
    "translate_key_active_2": "Aktiviert",
    "translate_key_disable": "Deaktivieren",
    "translate_key_disable_2": "Deaktiviert",
    "translate_key_phonenumber": "Bitte Ihre Telefonnummer",
    "translate_key_password_confirm": "Geben Sie Ihr Passwort bitte",
    "text_of_confirm_change_2FA_statut_to_desactivate": "Sind Sie sicher, dass Sie die Zwei-Faktor-Anmeldung für dieses Konto deaktivieren wollen ????   ",
    "translate_key_role_forward": "Roll forward",
    "translate_key_name_project": "Name des Projekts",
    "translate_key_forward_with_document": "Mit Dokumenten",
    "translate_key_forward_without_document": "Ohne Dokumente",
    "translate_key_description_project": "Beschreibung des Projekts",
    "translate_key_title_of_forward": "Wählen Sie die Aufgabe und die Aufgabenliste Ihres neuen Projektnamens aus:  {{name}} ",
    "translate_key_forward_ok": "Forward",
    "translate_key_last_uploaded_document": "Letztes hochgeladenes Dokument",
    "translate_key_document_edited": "Dokument bearbeiten",
    "translate_key_error_forward": "Sie müssen mindestens eine Aufgabenliste auswählen",
    "translate_key_document_downloaded_selected": "{{length}} Ausgewählte Dokumente",
    "translate_key_see_all_document": "mehr Dokumente anzeigen",
    "translate_key_see_all_document_active":"weniger anzeigen",
    "translate_key_roll_forward_subtitle": "Sie sind dabei, ein neues Projekt basierend auf diesem Projekt zu erstellen",
    "translate_key_roll_forward_selected_document": "Wählen Sie die Aufgaben und Dokumente aus, die Sie in das neue Projekt übernehmen möchten.",
    "translate_key_add_existing_user": "Vorhandenen Benutzer hinzufügen",
    "translate_key_document_in_task": "Dokument von {{name}}",
    "translate_key_all_selected":"Alles auswählen",
    "translate_key_save":"Sichern",
    "translate_key_receive_mail_warning_title":"Warnung",
    "translate_key_receive_mail_warning_description":"Wenn Sie bestätigen, dass Sie den Empfang von E-Mails deaktiviert haben, werden Sie keine E-Mails mehr erhalten!",
    "translate_key_receive_mail_warning_description_2":"Wollen Sie wirklich {{statut}} ?",
    "translate_key_not_data":"Leider liegen uns derzeit keine Daten vor.",
    "translate_key_message_success_receive_mail":"Erfolgreiche Änderung vorgenommen",
      "translate_key_all_document_version":"Alle Versionen des Dokuments {{name}}",
    "translate_key_no_document_retrive":"Kein Dokument finden",
    "translate_key_restore":"Restaurieren",
    "translate_ikey_text_restore":"Möchten Sie die Datei wiederherstellen? Diese Aktion kann nicht rückgängig gemacht werden. ",
    "translate_key_delete_version_title":"Löschen Dokument",
    "translate_key_delete_version_message":"Sind Sie sich wirklich sicher, dass Sie diese Version des Dokuments löschen wollen diese Aktion ist irreversibel ",
    "translate_key_document_drag":"Ziehen Sie das Dokument hierher und lassen Sie es los oder",
    "translate_key_document_type": "Alle Dokumenttypen (Word, PDF, Excel usw.).",
    "translate_key_document_browser_file":"Browse file",
    "translate_key_download_form_title":"Dokumente hochladen von",
    "translate_key_my_computer":"Mein Computer",
    "translate_key_datev_tile":"Datev",
    "translate_key_download_success":"Download erfolgreich durchführen",
    "translate_key_import_document":" Dokument importieren",
    "translate_key_invitation_send":"Einladung erfolgreich gesendet ",
    "translate_key_invitation_user":"PKF Benutzer einladen",
    "translate_key_invitation_title":"Benutzer zum Projekt einladen {{project}}",
    "translate_key_my_invitation_title":"Meine Einladungen",
    "translate_key_invitation_title_setting":"Verwaltung von Einladungen",
    "translate_key_received":"Empfangen",
    "translate_key_ACCEPTED":"Akzeptiert",
    "translate_key_REJECTED":"Ablehnen",
    "translate_key_PENDING":"Pending",
    "translate_key_DELETED":"Löschen",
    "translate_key_client_title":"PKF-Benutzer aus anderen Kanzleien für Mandant einladen",
    "translate_key_success_mail":"Eine E-Mail wurde an die Adresse {{email}} ",
    "translate_key_save_template": "Modifizierte Vorlage speichern",
    "translate_key_save_copy_template": "Als Kopiervorlage speichern",
    "translate_key_invite_user_to_project": "PKF-Benutzer aus anderen Kanzleien für Projekt einladen",
    "project_completion": "Prozentsatz erledigt",
    "assign_task": "Aufgabe zuweisen",
    "confirm_task_delete": "Möchten Sie diese Aufgabe wirklich löschen?",
    "add__new_user": "Mandant Benutzer hinzufügen",
    "added_to_favorites": "zu den Favoriten hinzugefügt",
    "clear": "Zurücksetzen",
    "invite_user_to_sign": "Hinzugefügte Kunden auffordern zu unterschreiben",
    "german": "Deutsch",
    "english": "English",
    "my_task_deadline": "Aufgaben mit Frist",
    "text_all_clients_page": "Hier sehen Sie alle derzeit aktiven Mandanten",
    "text_view_active_pkf_employess": "Hier sehen Sie alle derzeit aktiven PKF-Mitarbeiter, die Zugriff auf das PKF-Mandantenportal haben.",
    "invitation_not_found": "Einladung nicht gefunden",
    "about_pkf_germany": "PKF Deutschland zählt zu den leistungsfähigsten unabhängigen Wirtschaftsprüfungs- und Beratungsnetzwerken \
                          in Deutschland. Zu unserem Angebotsspektrum gehören neben den klassischen Dienstleistungen rund um \
                          Wirtschaftsprüfung und Steuerberatung insbesondere individuelle Beratungsleistungen im Bereich Corporate \
                          Finance und Digitalisierung. An 39 Standorten in Deutschland schaffen wir Nähe zu unseren Mandanten und bieten \
                          gleichzeitig den Kontakt zu unserem weltweiten Netzwerk in 144 Ländern.",
    "invite_user_placeholder": "Bitte geben Sie die E-Mail-Adresse des Nutzers ein",
    "datev_filter_title": "Bitte filtern auswählen, um die Dokumente zu suchen",
    "datev_ordner": "Ordner",
    "datev_register": "Register",
    "datev_state": "State",
    "datev_id_error": "Mandant hat keine Verbindung zu DATEV",
    "my_tasks": "Meine Aufgaben",
    "assigned_to": "Zugewiesene Aufgaben an"
  }
}