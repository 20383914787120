import { Template } from "./GlobalDataType";
import { User } from "./UserTypes";
import { Moment } from "moment";
import { Client } from "./GlobalDataType";
import { Activity, Positions, TaskList } from "./TaskType";

export interface ProjectPagination {
    page?: number;
    per_page?: number;
    keyword?: string;
    my_template?: boolean;
    is_default?: boolean;
    order_field?: string;
    order?:string
}


export interface FetchGrantUsersPayload {
    target: 'projects' | 'clients' | 'task-list' | 'tasks'
    uuid: string
}

export const getLockedUserEndpoint = (target: FetchGrantUsersPayload['target']): string => {
    switch (target) {
        case "clients":
            return 'client_uuid';
        case "task-list":
            return 'task_group_uuid'
        case "tasks":
            return 'task_uuid'
        case "projects":
            return 'project_uuid'
    }
}

export const lockUserEndpoint = (target: FetchGrantUsersPayload['target']): string => {
    switch (target) {
        case "clients":
            return 'clients';
        case "task-list":
            return 'tasks/groups'
        case "tasks":
            return 'tasks'
        case "projects":
            return 'projects'
    }
}

export interface ProjectData {
    total?: number;
    pages?: number;
    current_page?: number;
    per_page?: number;
    data?: Project[];
}

export interface Project {
    uuid?: string;
    name?: string;
    description?: string;
    status?: string;
    deadline?: string;
    site?: ProjectSite;
    client?: Client;
    users?: User[],
    tasks?: Task[];
    number_of_docs?:number;
    client_uuid?: string
    number_of_outstanding_task?: number;
    number_of_messages?: number;
    number_of_documents?: number;
    percent_task?: number;
    is_favorite?: boolean;
}


export interface ProjectSite {
    uuid?: string;
    name?: string;
    localisation?: string;
}

export interface TaskHomePayload {
    start_date: string
    end_date: string
}

export interface Task {
    uuid?: string,
    name?: string,
    position?: number,
    description?: string,
    deadline?: string;
    status?: string;
    unchanged?: boolean;
    parent_uuid?:string,
    task_group_uuid?:string,
    task_group?:TaskList,
    users?: User[],
    project?: Project,
    added_by?: User,
    number_of_documents?: number,
    tagged_user_uuids?:string[],
    modified_by?: User,
    date_added?: string
    date_modified?: string
    is_done?: boolean,
    is_assigned?: boolean
}


export interface ProjectOverview {
    activities?: Activity[];
    comments?: Activity[];
    users?: User[];
    status?: Status;
}

export interface Status {
    OPEN?: number;
    ACCEPTED?: number;
    SUFFERING?: number;
    REJECTED?: number;
    IN_PROGRESS?: number;
    DUE_THIS_WEEK?: number;
    PERCENT_TASK?: number;
    ASSIGNED?: number;
    DONE?: number;
}

export interface TemplateData {
    total?: number;
    pages?: number;
    current_page?: number;
    per_page?: number;
    data?: Template[];
}

export interface ClientProjets {
    total?: number;
    pages?: number;
    current_page?: number;
    per_page?: number;
    data?: Project[];
}

export interface ClientProjet {
    uuid?: string;
    name?: string;
}

export interface FilterParams {
    page?: number;
    per_page?: number;
    order?: string;
}

export interface HideTaks {
    total?: number;
    pages?: number;
    current_page?: number;
    per_page?: number;
    data?: HideTask[];
    position?: Positions;
}

export interface HideTask {
    uuid?: string;
    name?: string;
    status?: string;
    number_of_documents?: number;
}

export interface Position {
    task_uuid?: null;
    task_group_uuid?: null;
}

